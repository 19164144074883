import React from "react";
import Footer from "src/components/Footer";

class Page extends React.Component {
  render() {
    return (
      <div>
        <header>
          <div className="page_width rel">
            <nav>
              <a href="/" id="logo" />
              <span id="menu_btn" />
              <ul id="menu">
                <li>
                  <a href="/">Hlavná stránka</a>
                </li>
                <li>
                  <a href="/lieky/">Lieky</a>
                </li>
                <li>
                  <a href="/radca/">Radca</a>
                </li>
                <li>
                  <a href="/upravte_liek/">Test</a>
                </li>
                <li>
                  <a href="/kontakt/">Kontakt</a>
                </li>
                <li>
                  <a href="/otazky_a_odpovede/">Otázky a odpovede</a>
                </li>
                <li>
                  <a href="/kde_kupit/">KDE KÚPIŤ </a>
                </li>
              </ul>
            </nav>
          </div>
        </header>
        <div id="page" className="page_width">
          <div id="breadcrumbs">
            <a href="/">Hlavná stránka</a>
            <a href="/radca/#nadcha"> &gt; Radca(NÁDCHA)</a>
            <a href="/radca/nadcha/myty_o_nadche/" className="sel">
              {" "}
              &gt; Mýty o nádche
            </a>
          </div>
          <div id="page_content">
            <div className="col1">
              <div className="main_photo">
                <img src="/web/kcfinder/uploads/files/artykuly/Mity_o_katarze_03.jpg" />
              </div>
            </div>
            <div className="col2">
              <h1>Mýty o nádche</h1>
              <div className="wysiwyg">
                <div>
                  <strong>
                    Hneď ako nádcha udrie, budete sa cítiť naozaj „pod psa“. V
                    hlave sa Vám spustí kolotoč najrôznejších možností, ako s
                    ňou zatočiť. Čo ale platí a čo nie?
                  </strong>
                </div>
                <div>&nbsp;</div>
                <div>
                  <strong>Nádchu vyliečia minerály a morské soli</strong>
                </div>
                <div>
                  <strong>NIE.</strong> Nosové spreje s minerálmi a morskými soľami slúžia predovšetkým ako prevencia a zvlhčujú nosovú sliznicu. 
                  Na rozdiel od nosových sprejov (liekov) neobsahujú liečivo, 
                  ktoré pri dodržiavaní pokynov uvedených v priloženej informácii pre používateľa lieči nádchu.
                </div>
                <div>&nbsp;</div>
                <div>
                  <strong>
                    Všetko, čo môžete pri nádche robiť, je čakať, kým prejde
                  </strong>
                </div>
                <div>
                  <strong>NIE.</strong> Nádcha spôsobuje opuch nosovej sliznice.
                  Usilovné siakanie a ničnerobenie ju nevyrieši. Omnoho
                  účinnejšie je použiť nosový sprej, ktorý obsahuje liečivá, a
                  tie dokážu opuch zmierniť. Správnym používaním podľa
                  priloženej informácie pre používateľa docielite úľavu.
                </div>
                <div>&nbsp;</div>
                <div>
                  <strong>
                    Nádcha nie je vážne ochorenie, nevadí, keď s ňou budete
                    chodiť medzi ľudí
                  </strong>
                </div>
                <div>
                  <strong>NIE.</strong> Nádcha sa prenáša kvapôčkovou infekciou
                  a najmä v prvých troch dňoch môžete nakaziť veľa ľudí vo
                  svojom okolí. Lepšie je preto nádchu liečiť. Stála izbová
                  teplota, dostatok tekutín a telesný aj duševný odpočinok
                  naštartujú obranné mechanizmy, a tým pomôžu pri liečbe. Navyše
                  pokoj Vám pomôže vyhnúť sa možným komplikáciám v podobe
                  závažnejších ochorení.
                </div>
                <div>&nbsp;</div>
                <div>
                  <strong>
                    Spreje do nosa nezaberajú, nevyplatí sa ich používať
                  </strong>
                </div>
                <div>
                  <strong>NIE. </strong>Správnym používaním podľa priloženej
                  informácie pre používateľa docielite úľavu. Liečivo obsiahnuté
                  v nosových sprejoch uvoľní upchatý nos a pomôže vám lepšie
                  dýchať a spať. Pomocné látky obsiahnuté v niektorých nosových
                  sprejoch alebo nosových kvapkách majú navyše na nosovú
                  sliznicu ďalšie pozitívne účinky (napr. regenerácia,
                  zvlhčenie).
                </div>
                <div>&nbsp;</div>
                <div>
                  <strong>Bežnú nádchu vyliečia antibiotiká</strong>
                </div>
                <div>
                  <strong>NIE.</strong> Antibiotiká zaberajú iba na bakteriálne
                  infekcie. Pri liečbe nekomplikovanej akútnej nádchy nepomôžu.
                </div>
                <div>&nbsp;</div>
                <div>
                  <strong>Užívanie vitamínov nádchu vylieči</strong>
                </div>
                <div>
                  <strong>NIE.</strong> Teda nie tak úplne. Užívanie vitamínov
                  posilňuje odolnosť organizmu, môže teda pomôcť nádche predísť,
                  nie ju však vyliečiť.
                </div>
                <div>&nbsp;</div>
                <div>
                  <strong>Pobyt vonku v zime môže spôsobiť nádchu</strong>
                </div>
                <div>
                  <strong>NIE.</strong> Bolo by jednoduché všetko zviesť na
                  matku prírodu, ale úplne tomu tak nie je. Vírusom spôsobujúcim
                  nádchu sa najlepšie darí vo vykúrených a uzavretých
                  miestnostiach.
                </div>
                <div>&nbsp;</div>
                <div>
                  <strong>Nádcha sa prenáša bozkávaním</strong>
                </div>
                <div>
                  <strong>NIE.</strong> Najčastejšie z rodiny virusov sú pri nádche rinovírusy, ktoré sa do tela 
                  dostávajú najmä sliznicou očí a nosa.
                </div>
                <div>&nbsp;</div>
                <div>
                  <strong>Pri nádche šport nevadí</strong>
                </div>
                <div>
                  <strong>NIE.</strong> Pri nádche je oslabený imunitný systém.
                  Veľká fyzická námaha môže viesť k závažnejším a
                  komplikovanejším ochoreniam, ako sú napríklad angína alebo
                  zápal priedušiek. V čase prechladnutia je teda lepšie šport
                  úplne vynechať, venovať sa viac odpočinku a dopriať si
                  dostatočný spánok, ktorý posilní obranné mechanizmy.
                </div>
                <div>&nbsp;</div>
                <div>
                  <strong>
                    Najlepšou prevenciou nádchy je dostatočne teplé oblečenie
                  </strong>
                </div>
                <div>
                  <strong>NIE.</strong> Aj keď nás mamičky a babičky celé
                  detstvo a dokonca aj v dospelosti nabádali k tomu, aby sme
                  „vrstvili“ a poriadne sa obliekali, nemá nádcha s pobytom v
                  chlade nič spoločného. Avšak teplé oblečenie pomáha pred
                  prechladnutím organizmu. A ako vieme, prechladnutý organizmus
                  znamená oslabenú imunitu a tým pádom väčšiu náchylnosť na
                  nachladnutie.
                </div>
                <div>&nbsp;</div>
                <div>
                  <strong>Siakanie uvoľní upchatý nos</strong>
                </div>
                <div>
                  <strong>NIE.</strong> Dôvodom, prečo sa nám pri nádche zle
                  dýcha, nie je iba zahlienený nos, ale aj zdurenie nosovej
                  sliznice.
                </div>
                <div>&nbsp;</div>
              </div>
              <div className="other_article">
                <p className="name">Ďalšie články</p>
                <a
                  href="/radca/nadcha/ako_si_spravne_cistit_nos/"
                  className="box"
                >
                  <span className="photo" style={{ backgroundImage: "url(/web/kcfinder/uploads/files/artykuly/shutterstock_300834140.jpg)"}} />
                  <span className="title">
                    Ako si správne čistiť nos pri nádche?
                  </span>
                </a>
                <a href="/radca/nadcha/spoznajte_typy_nadchy/" className="box">
                  <span className="photo" style={{ backgroundImage: "url(/web/kcfinder/uploads/files/artykuly/shutterstock_388715146.jpg)"}} />
                  <span className="title">Spoznajte typy nádchy</span>
                </a>
                <a
                  href="/radca/nadcha/zabojujte_s_nadchou_rychlo/"
                  className="box"
                >
                  <span className="photo" style={{ backgroundImage: "url(/web/kcfinder/uploads/files/shutterstock_152527238.jpg)"}} />
                  <span className="title">Zabojujte s nádchou rýchlo</span>
                </a>
              </div>
            </div>
            <div className="clear" />
          </div>{" "}
        </div>
        <Footer></Footer>{" "}
        
      </div>
    );
  }
}

export default Page;
